/* Base transitions */
* {
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
}

/* Smooth theme transition */
.theme-transition {
  transition: background-color 0.2s ease-in-out,
              color 0.2s ease-in-out,
              border-color 0.2s ease-in-out;
}

/* Dark mode specific styles */
.dark {
  color-scheme: dark;
}

/* Chart transitions */
.chart-container {
  transition: background-color 0.2s ease-in-out;
}

/* Loading animation */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: spin 1s linear infinite;
}

/* Fade animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 0.2s ease-in-out;
}

/* Prevent flash of wrong theme */
.no-transition {
  transition: none !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Custom focus styles */
:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(99, 102, 241, 0.5);
}

/* Dark mode focus styles */
.dark :focus {
  box-shadow: 0 0 0 3px rgba(79, 70, 229, 0.5);
}

/* Disable transitions during theme switch to prevent flash */
.disable-transitions * {
  transition: none !important;
}