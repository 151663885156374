@tailwind base;
@tailwind components;
@tailwind utilities;

/* Base styles */
@layer base {
  body {
    @apply bg-gray-50 text-gray-900 transition-colors duration-200;
  }

  body.dark {
    @apply bg-dark-bg text-dark-text;
  }

  /* Add smooth transition for all elements */
  * {
    @apply transition-colors duration-200;
  }
}

/* Dark mode styles */
.dark {
  color-scheme: dark;
}

/* Card styles */
.card {
  @apply bg-white dark:bg-dark-card rounded-lg shadow-lg transition-colors duration-200;
}

.card-hover {
  @apply hover:bg-gray-50 dark:hover:bg-dark-card-hover transition-colors duration-200;
}

/* Chart customization */
.chart-container {
  @apply bg-white dark:bg-dark-card rounded-lg shadow-lg overflow-hidden transition-colors duration-200;
}

.dark .apexcharts-canvas {
  @apply bg-dark-card;
}

.dark .apexcharts-text {
  @apply fill-gray-300;
}

.dark .apexcharts-grid line {
  @apply stroke-gray-700;
}

.dark .apexcharts-tooltip {
  @apply bg-gray-800 border-gray-700;
}

.dark .apexcharts-tooltip-title {
  @apply bg-gray-700 border-gray-600;
}

/* Table styles */
.table-row-alt:nth-child(odd) {
  @apply bg-gray-50 dark:bg-gray-800;
}

.table-row-alt:nth-child(even) {
  @apply bg-white dark:bg-gray-900;
}

.table-row-hover {
  @apply hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-200;
}

/* Button styles */
.btn {
  @apply px-4 py-2 rounded-md font-medium transition-colors duration-200;
}

.btn-primary {
  @apply bg-primary-light text-white hover:bg-primary-dark;
  @apply dark:bg-primary-dark dark:hover:bg-primary-light;
}

.btn-secondary {
  @apply bg-gray-200 text-gray-700 hover:bg-gray-300;
  @apply dark:bg-gray-700 dark:text-gray-200 dark:hover:bg-gray-600;
}

/* Input styles */
.input {
  @apply bg-white dark:bg-dark-card border border-gray-300 dark:border-gray-600;
  @apply rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-primary-light dark:focus:ring-primary-dark;
  @apply text-gray-900 dark:text-gray-100 placeholder-gray-500 dark:placeholder-gray-400;
}

/* Custom scrollbar */
::-webkit-scrollbar {
  @apply w-2 h-2;
}

::-webkit-scrollbar-track {
  @apply bg-gray-100 dark:bg-gray-800 rounded-full;
}

::-webkit-scrollbar-thumb {
  @apply bg-gray-300 dark:bg-gray-600 rounded-full;
  @apply hover:bg-gray-400 dark:hover:bg-gray-500;
}

/* Font settings */
.font-afacad {
  font-family: 'Afacad', system-ui, -apple-system, sans-serif;
}

/* Loading animation */
.loading-spinner {
  @apply animate-spin rounded-full border-2 border-gray-300 dark:border-gray-600;
  @apply border-t-primary-light dark:border-t-primary-dark;
}